import { IResponseModel } from './apiModel/publicApi';

const HOST = 'https://test-api-internal.danielwellington.com'
const BASE_PATH = `store-management`

export const makeRequest = async <T = any>(url: string, options: RequestInit = {}): Promise<IResponseModel<T>> => {
    try {
        const headers = {
            'x-api-key': '1mZ8S2jAj470IwAc6HWKPaKjbFfTrG169I6Icd0s',
            ...options.headers
        }
        const params: RequestInit = {
            ...options,
            headers
        }
        const queryUrl = url.includes('https') ? url : `${HOST}/${BASE_PATH}/${url}`
        const response = await fetch(queryUrl, params);
        const parsedResponse = await response.json();

        if (response.ok) return parsedResponse;
        throw parsedResponse;
    } catch (e) {
        return e;
    }
};

export const formatStrParams = (query: object): string => {
    const paramsStr = (Object.entries(query)?.reduce((str, [key, value]) => (str += `&${key}=${value}`), ''))
    return paramsStr
}

export const makeRequest_timeout = <T = any>(fecthPromise, timeout = 1000): Promise<IResponseModel<T>> => {
    let controller = new AbortController();
    let abort: any = null;
    let abortPromise = new Promise((resolve, reject) => {
        abort = () => {
            return reject({
                message: '请求超时！请稍后重试！',
                success: false
            });
        };
    });
    let resultPromise = Promise.race([fecthPromise, abortPromise]);
    setTimeout(() => {
        abort();
        controller.abort();
    }, timeout);

    return resultPromise.then((res) => {
        clearTimeout(timeout);
        return res;
    });
}
